// Slider functionality for the history page. Adds and removes classes within the twig for loop using active, next and last to loop through slide content.
// access variables
const slideCont = document.querySelector('.equipment_slider')
const slide = document.querySelectorAll('.slide')
const slider = document.querySelector('equipment_slider_inner')
const prevBtn = document.querySelector('.left_arrow')
const nextBtn = document.querySelector('.right_arrow')
const pause = document.querySelector('.pause')
const play = document.querySelector('.play')
const first = slide[0]
const last = slide[slide.length - 1]
let timer

// add active and last classes
window.addEventListener('DOMContentLoaded', addClasses)
if (play) {
  play.addEventListener('click', function () {
    timer = setInterval(startSlider, 7000)
  })
}
if (pause) {
  pause.addEventListener('click', function () {
    clearTimeout(timer)
  })
}
function addClasses() {
  if (!!first && !!last) {
    first.classList.remove('next_slide')
    first.classList.add('active')
    last.classList.remove('next_slide')
    last.classList.add('last_slide')
  }
  timer = setInterval(startSlider, 7000)
}

// handle sliding classes along loop
const startSlider = (type) => {
  const active = document.querySelector('.active')
  if (active) {
    let last_slide = document.querySelector('.last_slide')
    let next = active.nextElementSibling
    if (!next) {
      next = first
    }
    active.classList.remove('active')
    last_slide.classList.remove('last_slide')
    next.classList.remove('next_slide')

    slideCont.setAttribute('style', `height:${next.offsetHeight}px;`)

    if (type === 'prev') {
      active.classList.add('next_slide')
      last_slide.classList.add('active')
      next.classList.add('next_slide')
      next = last_slide.previousElementSibling
      if (!next) {
        next = last
      }
      next.classList.remove('next_slide')
      next.classList.add('last_slide')
      return
    }
    active.classList.add('last_slide')
    last_slide.classList.add('next_slide')
    next.classList.add('active')
  }
}
// sliding the wave animation along the loop

// chevron function calls
if (!!nextBtn) {
  nextBtn.addEventListener('click', () => {
    clearTimeout(timer)
    startSlider('next')
  })
}
if (!!prevBtn) {
  prevBtn.addEventListener('click', () => {
    clearTimeout(timer)
    startSlider('prev')
  })
}
