// listen for products nav item click and toggle mega menu class
const menuButton = document.getElementById('megaMenuTrigger')
menuButton.addEventListener(
  'click',
  function (event) {
    event.preventDefault()
    document.getElementById('megaMenu').classList.toggle('megaMenu--is-open')
  },
  false
)

const navToggle = document.querySelector('.nav-toggle')
const navExit = document.querySelector('.nav-escape')
const mobileNav = document.querySelector('.mobile-nav')

navToggle.addEventListener('click', function () {
  mobileNav.classList.toggle('toggled')
  navToggle.classList.toggle('move')
})
navExit.addEventListener('click', function () {
  mobileNav.classList.toggle('toggled')
  navToggle.classList.toggle('move')
})
