const circles = document.querySelectorAll('.timeline-circle')





window.addEventListener('scroll', getOffset)
function getOffset (){
    let scrollPos = window.scrollY
    console.log(scrollPos);
    for(let i = 0; i<circles.length; i++){
        let top = circles[i].getBoundingClientRect().top - 350
        let bottom = circles[i].getBoundingClientRect().bottom + 250
        if(top <= 0 && bottom >= 0){
            circles[i].classList.add('active_year')
        }else{      
            circles[i].classList.remove('active_year')
        }
    }
}

