const tab = document.querySelectorAll('.service_links')
const info = document.querySelectorAll('.content_bar')
const firstTab = info[0]

window.addEventListener('DOMContentLoaded', tabClasses)

function tabClasses() {
  if (firstTab) {
    firstTab.classList.remove('hidden')
  }
}

for (let i = 0; i < tab.length; i++) {
  tab[i].addEventListener('click', tabChange)
}

function tabChange(e) {
  let target = e.target.id
  for (let i = 0; i < info.length; i++) {
    if (target !== info[i].id) {
      info[i].classList.add('hidden')
    } else {
      info[i].classList.remove('hidden')
    }
  }
}
